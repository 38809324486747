import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { Shffl } from '../components/Shffl';

export class ScrollItem__Default extends VScroll_Item {
  shffl = [];

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================

  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this.setAnimations();

    this.onShow = () => {
      console.log('Show');

      this.showAnimations();
    };
    this.onHide = () => { };
    this.onMove = () => { };
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  setAnimations() {
    const shffl = GetBy.selector('[data-shffl]', this.item);

    for (let i = 0; i < shffl.length; i++) {
      this.shffl.push(new Shffl(shffl[i], {
        origin: Shffl.LEFT,
        duration: Math.max(.6, Math.min(1.5, shffl[i].innerText.length / 25)),
        textFrom: shffl[i].innerText,
        textTo: shffl[i].innerText
      }));
    }
  }

  showAnimations() {
    this.shffl.map(shf => shf.play());
  }
}

Scroll._registerClass('default', ScrollItem__Default);
