import gsap, { Power2 } from 'gsap';
import { Basics, isDebug, isMobile } from '../_app/cuchillo/core/Basics';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';

export default class FormSlider {
    currentSlide;
    container;
    slides;
    holder;
    prevButton;
    nextButton;
    scrollbarThumb;
    slides = [];
    totalScore = 0;
    twitterButton;
    copyButton;
    footerElement;

    constructor(containerElement) {
        this.currentSlide = 0;
        this.container = containerElement;
        this.form = GetBy.selector('form', this.container)[0];
        this.slidesElements = GetBy.class('slide', this.container);
        this.holder = GetBy.class('__holder')[0];
        this.prevButton = GetBy.selector('button[data-prev]')[0];
        this.nextButton = GetBy.selector('button[data-next]')[0];
        this.scrollbarThumb = GetBy.selector('.scrollbar .thumb')[0];
        this.footerElement = GetBy.class('__footer')[0];

        this.restartButton = GetBy.class('__restart', this.container)[0];
        this.twitterButton = GetBy.class('__twitter-btn', this.container)[0];
        this.copyButton = GetBy.class('__copy-btn', this.container)[0];

        for (let i = 0; i < this.slidesElements.length; i++) {
            const element = this.slidesElements[i];
            let slideInstance;

            if (i === this.slidesElements.length - 2) {
                slideInstance = new SlideEmail(element, this);
            } else if (i === this.slidesElements.length - 1) {
                slideInstance = new SlideLast(element, this);
            } else {
                slideInstance = new Slide(element, this);
            }

            this.slides.push(slideInstance);
        }

        this.initEventListeners();
        this.showSlide(this.currentSlide);
    }

    initEventListeners() {
        this._restart = () => this.restartForm();
        this.restartButton.addEventListener(Basics.clickEvent, this._restart);

        this._change = i => this.handleRadioButtonChange(i);
        this.slides.forEach((slide, index) => {
            slide.radioButtons.forEach(radio => {
                radio.addEventListener('change', this._change);
            });
        });

        this._share = () => this.shareOnTwitter();
        this._copy = () => this.copyToClipboard();
        this.twitterButton.addEventListener(Basics.clickEvent, this._share);
        this.copyButton.addEventListener(Basics.clickEvent, this._copy);

        this._submit = e => this.submit(e);
        this.form.addEventListener('submit', this._submit);

        if (isMobile) return;

        this._next = () => this.nextSlide();
        this._prev = () => this.prevSlide();
        this.nextButton.addEventListener(Basics.clickEvent, this._next);
        this.prevButton.addEventListener(Basics.clickEvent, this._prev);
    }

    handleRadioButtonChange() {
        this.slides[this.currentSlide].autoAdvance();
    }

    submit(e) {
        // if (isDebug) e.preventDefault();
        this.showLast();
    }

    copyToClipboard() {
        const textarea = document.createElement('textarea');
        textarea.value = 'https://www.ialiada.felgtbi.org/';
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        alert('URL copiada al portapapeles');
    }

    shareOnTwitter() {
        const url = 'https://www.ialiada.felgtbi.org/';
        //         const tweetText = `¡Acabo de hacer el test y he conseguido una puntuación de ${this.formatScore(this.totalScore)}!
        // 👉🏼🏳️‍🌈 ${url}`;
        const tweetText = `¡Acabo de calcular mis niveles de #InteligenciaAliada y he conseguido ${this.formatScore(this.totalScore)} puntos!

Si tú también quieres descubrir la Inteligencia que puede acabar con los discursos de odio, entra en 👉🏼 ${url}`;

        const twitterLink = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}`;
        window.open(twitterLink, '_blank');
    }

    showSlide(index) {
        this.currentSlide = index;
        this.updateProgress()
        this.updateButtons();

        if (isMobile) {
            this.slides.forEach((slide, i) => {
                if (i === index) {
                    slide.show();
                } else {
                    slide.hide();
                }
            });
        } else {
            this.slides.forEach((slide, i) => {
                gsap.to(slide.dom, { x: -index * 100 + '%', duration: .5, delay: .2, ease: Power2.easeOut });

                if (i === index) {
                    slide.show();
                } else {
                    slide.hide();
                }
            });
        }
    }

    showLast() {
        const finalSlideIndex = this.slides.length - 1;
        this.showSlide(finalSlideIndex);
    }

    nextSlide() {
        if (this.currentSlide < this.slides.length - 2 && this.slides[this.currentSlide].isQuestionAnswered()) {
            this.totalScore += this.slides[this.currentSlide].getScore();
            this.showSlide(this.currentSlide + 1);
        } else if (this.currentSlide === this.slides.length - 2) {
            this.showSlide(this.currentSlide + 1);
        }
    }

    prevSlide() {
        if (this.currentSlide > 0) {
            this.totalScore -= this.slides[this.currentSlide].getScore(); // Resta el resultado de la diapositiva actual al resultado total
            this.showSlide(this.currentSlide - 1);
        }
    }

    updateProgress() {
        const progress = this.currentSlide / (this.slides.length - 2);
        gsap.to(this.scrollbarThumb, { scaleX: progress });

        if (this.currentSlide > this.slides.length - 2) {
            gsap.to(this.footerElement, { y: '200%', duration: .8, ease: Power2.easeOut });
        } else {
            gsap.to(this.footerElement, { y: '0%', duration: .8, ease: Power2.easeOut });
        }
    }

    updateButtons() {
        this.prevButton.disabled = this.currentSlide === 0;
        this.nextButton.disabled = !this.slides[this.currentSlide].isQuestionAnswered();
    }

    restartForm() {
        this.showSlide(0);
        this.slides.forEach(slide => { slide.restart() });
    }

    dispose() {
        this.twitterButton.removeEventListener(Basics.clickEvent, this._share);
        this.copyButton.removeEventListener(Basics.clickEvent, this._copy);
        this.restartButton.removeEventListener(Basics.clickEvent, this._restart);

        this.slides.forEach(slide => {
            slide.dispose();
            slide.radioButtons.forEach(radio => {
                radio.removeEventListener('change', this._change);
            });
        });

        this.form.removeEventListener('submit', this._submit);

        if (isMobile) return;

        this.nextButton.removeEventListener(Basics.clickEvent, this._next);
        this.prevButton.removeEventListener(Basics.clickEvent, this._prev);
    }

    formatScore(score) {
        return score.toFixed(3).replace(/(\d+)(\.\d+)?/, (_, integer, decimal) => {
            return integer.padStart(3, '0') + (decimal || '');
        });
    }
}

class Slide {
    dom;
    radioButtons;
    formSlider;
    autoAdvanceFlag = false;

    constructor(element, instance) {
        this.dom = element;
        this.formSlider = instance;
        this.radioButtons = GetBy.selector('input[type="radio"]', this.dom);
    }

    isQuestionAnswered() {
        return Array.from(this.radioButtons).some(radio => radio.checked);
    }

    getScore() {
        const checkedRadio = GetBy.selector('input[type="radio"]:checked', this.dom)[0];
        const score = checkedRadio ? parseInt(checkedRadio.getAttribute('data-score')) : 0;
        return score;
    }

    autoAdvance() {
        this.formSlider.nextSlide();

        if (isMobile) return;
        if (!this.autoAdvanceFlag) this.autoAdvanceFlag = true;
    }

    show() {
        this.dom.classList.add('active');
        this.dom.classList.remove('slide-inactive');
    }

    hide() {
        this.dom.classList.remove('active');
        this.dom.classList.add('slide-inactive');
    }

    restart() {
        this.autoAdvanceFlag = false;

        this.radioButtons.forEach(radio => {
            radio.checked = false;
        });
    }

    dispose() { }
}

class SlideEmail extends Slide {
    emailInput;
    ctaButton;

    constructor(element, instance) {
        super(element, instance);

        this.emailInput = GetBy.selector('input[type="email"]', this.dom)[0];
        this.nameInput = GetBy.selector('input[type="text"]', this.dom)[0];
        this.checkboxInput = GetBy.selector('input[type="checkbox"]', this.dom)[0];
        this.checkboxInput2 = GetBy.selector('input[type="checkbox"]', this.dom)[1];

        this.ctaButton = GetBy.class('__submit', this.dom)[0];
        this.initEventListeners();
    }

    initEventListeners() {
        this._ctaClick = (event) => this.handleCtaClick(event);
        this.ctaButton.addEventListener(Basics.clickEvent, this._ctaClick);
    }

    handleCtaClick(event) {
        const email = this.emailInput.value;
        const name = this.nameInput.value;
        const isChecked = this.checkboxInput.checked;

        if (!this.isRequired(name)) {
            event.preventDefault();
            alert('Por favor, introduce tu nombre.');
        } else if (!this.isValidEmail(email)) {
            event.preventDefault();
            alert('Por favor, introduce un correo electrónico válido.');
        } else if (!isChecked) {
            event.preventDefault();
            alert('Por favor, marca la casilla de términos y condiciones para continuar.');
        }
    }

    restart() {
        super.restart();
        this.emailInput.value = '';
        this.nameInput.value = '';
        this.checkboxInput.checked = false;
        this.checkboxInput2.checked = false;
    }

    dispose() {
        this.ctaButton.removeEventListener(Basics.clickEvent, this._ctaClick);
    }

    isValidEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    isRequired(field) {
        return field.trim() !== '';
    }
}

class SlideLast extends Slide {
    score = 0;

    constructor(element, instance) {
        super(element, instance);

        if (isMobile) this.dom.style.display = 'none';
    }

    show() {
        super.show();

        if (isMobile) {
            this.dom.style.display = 'block';
            document.getElementById('SlideLast').scrollIntoView();
        }

        const resultSpan = GetBy.selector('.__result', this.dom)[0];
        const totalScore = this.formSlider.totalScore;

        resultSpan.textContent = `000.000PT`;

        gsap.killTweensOf(this);
        gsap.to(this, {
            score: totalScore,
            onUpdate: () => {
                const formattedScore = this.formSlider.formatScore(this.score) + 'PT';
                resultSpan.textContent = formattedScore;
            },
            delay: .5,
            duration: 1.5,
            ease: Power2.easeOut
        });
    }

    restart() { }
}
