import { Videos } from '../_app/cuchillo/components/Videos';
import { Acordions } from '../_app/cuchillo/components/Acordions';
import { Basics, isTouch } from '../_app/cuchillo/core/Basics';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Page from '../_app/cuchillo/pages/Page';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import Preloader from '../layout/Preloader';
import Wrap from '../layout/Wrap';
import Keyboard from '../_app/cuchillo/core/Keyboard';
import { Shffl } from '../components/Shffl';
import { GetBy } from '../_app/cuchillo/core/Element';

export default class Default extends Page {
  hovers = [];

  constructor() {
    super();

    Videos.init();
    Acordions.init();

    this.setShffleBtns();
  }

  //SHOW
  beforeShow() {
    super.beforeShow();
    Scroll.init(Scroll.AXIS_Y, { domResize: this.container, smooth: !isTouch, multiplicator: 1 });
    Keyboard.mountPage(this.id);
  }

  setShffleBtns() {
    const btns = GetBy.selector('[data-shffl-btn]');

    this._hoverIn = (e) => this.hovers[e.target.dataset.index].play();
    this._hoverOut = (e) => this.hovers[e.target.dataset.index].reverse();

    for (let i = 0; i < btns.length; i++) {
      const btn = btns[i];
      btn.dataset.index = i;

      const shffl = new Shffl(btn, {
        origin: Shffl.RANDOM,
        duration: .8,
        textFrom: btn.innerText,
        textTo: btn.innerText,
        exactSize: true
      });

      this.hovers.push(shffl);
      btn.addEventListener(Basics.mouseOver, this._hoverIn);
      btn.addEventListener(Basics.mouseOut, this._hoverOut);
    }
  }

  show__effect(__call) {
    if (this.isFirstTime) {
      Wrap.show(() => {
        Preloader.hide(() => { this.afterShow() });
      });
    } else {
      Wrap.show(() => { this.afterShow() });
    }
  }

  afterShow() {
    super.afterShow();
    Scroll.start();
    Scroll.show();
  }

  //HIDE
  beforeHide() {
    Keyboard.unmountPage(this.id);
    super.beforeHide();
  }

  hide__effect() {
    Scroll.hide();
    Wrap.hide(() => { this.afterHide(); });
  }

  afterHide() {
    console.log('AFTER HIDE');

    Scroll.dispose();
    super.afterHide();
  }

  //RESIZE
  resize() {
    super.resize();
    Acordions.resize();
  }

  //LOOP
  loop() {
    if (this._isActive) {
      super.loop();
    }
  }

  dispose() {
    const btns = GetBy.selector('[data-shffl-btn]');

    for (let i = 0; i < btns.length; i++) {
      const btn = btns[i];
      btn.removeEventListener(Basics.mouseOver, this._hoverIn);
      btn.removeEventListener(Basics.mouseOut, this._hoverOut);
    }

    super.dispose();
  }
}

ControllerPage._addPage('default', Default);