import gsap from 'gsap';

import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { ScrollItem__Default } from './ScrollItem__Default';
import { Maths } from '../_app/cuchillo/utils/Maths';

export class ScrollItem__Landing extends ScrollItem__Default {
  scrollbar;
  scrollbarsVertical = [];
  _slider;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================

  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    const scrollVertical = GetBy.class('scrollbar-vertical', __link);
    for (let i = 0; i < scrollVertical.length; i++) {
      const element = scrollVertical[i];
      this.scrollbarsVertical.push(new ScrollbarVertical(element));
    }

    this.onMove = () => {
      const p = 1 - this.progressInside;

      for (let i = 0; i < this.scrollbarsVertical.length; i++) {
        const element = this.scrollbarsVertical[i];
        element.update(p)
      }
    }
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  setAnimations() {
    super.setAnimations();
  }

  showAnimations() {
    super.showAnimations();
  }
}

Scroll._registerClass('block-landing', ScrollItem__Landing);

class ScrollbarVertical {
  thumb;

  constructor(__dom, __nSlides) {
    this.thumb = GetBy.class('thumb', __dom)[0];
  }

  update(__p) {
    const p = Math.floor(__p * 10);
    const scaleY = Maths.clamp(p * .1, 0, 1);
    gsap.to(this.thumb, { scaleY, duration: .1 });
  }
}
