import gsap from 'gsap';

import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { SliderHorizontalScroll } from '../_app/cuchillo/components/SliderHorizontalScroll';
import { ScrollItem__Default } from './ScrollItem__Default';
import { Functions } from '../_app/cuchillo/utils/Functions';

const POSITIONS = ['--pos-0', '--pos-1', '--pos-2', '--pos-3', '--pos-4'];

export class ScrollItem__Tweets extends ScrollItem__Default {
  scrollbar;
  _slider;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    let POS = [...POSITIONS];
    const nSlides = GetBy.class('__slide', __link);
    for (let i = 0; i < nSlides.length; i++) {
      const element = nSlides[i];
      POS = Functions.arrayRandom(POS);
      const c = POS.pop();
      element.classList.add(c);

      if (POS.length === 0) POS = [...POSITIONS];
    }

    this.scrollbar = new Scrollbar(GetBy.class('scrollbar', __link)[0], nSlides.length);

    this._slider = new SliderHorizontalScroll(__link,
      {
        interaction: false,
        hasLimits: false,
        itemClass: SliderScroll__Horizontal__Item
      });

    this.onMove = () => {
      const p = 1 - this.progressInside;
      this._slider.progress = p;
      this._slider.loop();
      this.scrollbar.update(p);
    }
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  setAnimations() {
    super.setAnimations();
  }

  showAnimations() {
    super.showAnimations();
  }
}

Scroll._registerClass('block-tweets', ScrollItem__Tweets);

class SliderScroll__Horizontal__Item extends VScroll_Item {
  hasHiddenEnabled = false;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this.onShow = () => { };
    this.onHide = () => { };
    this.onMove = () => { };
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  mouseOver() { }
  mouseDown() { }
  mouseUp() { }

  show() {
    super.show();
  }

  hide() {
    super.hide();
  }
}

class Scrollbar {
  thumb;

  constructor(__dom, __nSlides) {
    this.thumb = GetBy.class('thumb', __dom)[0];
    this.threshold = 1 / (__nSlides + 2);
  }

  update(__p) {
    const p = Math.floor(__p * 10);
    gsap.to(this.thumb, { scaleX: p * .1, duration: .1 });
  }
}
