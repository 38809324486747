import { isMobile } from '../_app/cuchillo/core/Basics';
import { GetBy } from '../_app/cuchillo/core/Element';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import FormSlider from '../components/FormSlider';
import Default from './Default';

export default class Form extends Default {
  formSlider;

  constructor() {
    super();

    this.formSlider = new FormSlider(GetBy.id('Form'));
  }

  afterShow() {
    super.afterShow();
    if (!isMobile) Scroll.stop();
  }
}

ControllerPage._addPage('form', Form);