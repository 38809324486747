import gsap, { Power3 } from "gsap";

import { GetBy } from "../_app/cuchillo/core/Element";
import { isDebug } from '../_app/cuchillo/core/Basics';
import { Shffl } from '../components/Shffl';

export default class Preloader {
    static container;
    static thumb = 0;
    static progress = 0;
    static shffl = [];
    static preloader = false;

    static setAnimations() {
        const shffl = GetBy.selector('[data-shffl]', this.container);

        for (let i = 0; i < shffl.length; i++) {
            this.shffl.push(new Shffl(shffl[i], {
                origin: Shffl.LEFT,
                duration: 2,
                textFrom: '',
                textTo: shffl[i].innerText
            }));
        }
    }

    static showAnimations() {
        this.shffl.map(shf => shf.play());
    }

    static init() {
        this.container = GetBy.id('Preloader');

        if (this.container) {
            this.preloader = true;
            this.thumb = GetBy.class('thumb', this.container)[0];
            this.setAnimations();
        } else {
            this.container = GetBy.id('Preloader2');
        }
    }

    static update(__progress) { }

    static hide(__call) {
        if (isDebug) {
            this.container.style.display = 'none';
            if (__call) __call();
        } else if (!this.preloader) {
            gsap.to(this.container, {
                opacity: 0,
                duration: .6,
                onComplete: () => {
                    this.container.style.display = 'none';
                    if (__call) __call();
                }
            });
        } else {
            this.showAnimations();

            gsap.to(this, {
                progress: 5,
                duration: 2,
                delay: 2,
                ease: Power3,
                onUpdate: () => {
                    const p = Math.floor(this.progress);
                    gsap.to(this.thumb, { scaleX: p * .2, duration: .1 })
                }
            });

            gsap.to(this.container, {
                opacity: 0,
                duration: .6,
                delay: 5,
                onComplete: () => {
                    this.container.style.display = 'none';
                    if (__call) __call();
                }
            });
        }
    }
}
